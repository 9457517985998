<div class="shipment-entry-job-steps">
  <nz-steps [nzCurrent]="0" nzProgressDot nzDirection="vertical">
    <nz-step *ngIf="hasRoute" [nzTitle]="routeStepHeader">
      <ng-template #routeStepHeader>
        <div nz-row class="routeHeader">
          <div class="routeTitle">Route: {{job?.code}}</div>
          <div class="actionButton left20">
            <span class="view-pod"><a (click)="viewPOD(job)">View POD</a></span>
            <span class="view-notes"><a (click)="viewNotes(job)">View Notes</a></span>
            <span class="go-to-dispatch"><a target="_blank" [routerLink]="[routeAdminDispatchList, job.id]" [queryParams]="{search: shipmentWarpIdGoToDispatch}">Go to Dispatch</a></span>
          </div>
        </div>
      </ng-template>
    </nz-step>
    <nz-step *ngFor="let location of locations; let i = index" [nzDescription]="locationStep" >
      <ng-template #locationStep>
        <nz-collapse class="locationCollapse">
          <nz-collapse-panel [nzHeader]="collapseHeader" [nzShowArrow]="false" [nzActive]="true">
            <ng-template #collapseHeader>
              <div class="mainTitleCollapse">
                <div class="flex1">
                  <img src="assets/img/location-icon.svg">
                  <span *ngIf="isPickupLocation(location)" class="pickup-tag">Pickup <code>{{getShipmentWarpIdForLocation(location)}}</code></span> 
                  <span *ngIf="isDropoffLocation(location)" class="dropoff-tag">Delivery <code>{{getShipmentWarpIdForLocation(location)}}</code></span> 
                  <span ><a class="left10" target="_blank" (click)="saveLocation(location)" [routerLink]="routeAdminLocationList">{{location?.locationName}}</a></span>
                  <span class="font-regular left10"> {{getAddressText(location?.addr)}}</span>
                  <span *ngIf="isTurnOnEditMode">
                    <a class="edit-item-shipment-entry" (click)="onBtnEditLocation(location); $event.stopPropagation()">
                      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                    </a>
                  </span>
                  <span class="warning-address" *ngIf="isWarningAddress(location)">Unrecognized address.</span>
                </div>
                <div>
                  <img src="assets/img/settings_photo_camera.svg" (click)="onBtnChangeDeliveryInfoSettings(location); $event.stopPropagation()">
                </div>
              </div>
              <div nz-row class="subTitleCollapse">
                <div nz-col nzXXl="12" nzXl="12" nzLg="12" class="appointmentDate flex" style="flex-wrap: wrap;">
                  <div class="flex right20">
                    <img src="assets/img/appointment-date.svg" style="align-self: flex-start; margin-right: 5px;">
                    <div [innerHTML]="getTimePeriods(location)"></div>
                    <div *ngIf="isTurnOnEditMode">
                      <a class="edit-item-shipment-entry" (click)="onBtnEditWindowsTime(location); $event.stopPropagation()">
                        <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                      </a>
                    </div>
                  </div>
                  <div *ngIf="isAppointmentRequired(location)">
                    <img src="assets/img/appointment-date.svg" style="margin-right: 5px;">
                    <span class="font-medium">{{getAppointmentText(location)}}</span>
                    <span *ngIf="hasAppointmentTime(location) && !clearAppoinmentLoading" (click)="clearAppointmentTime(location); $event.stopPropagation()" nz-icon [nzType]="'close-circle'" style="cursor: pointer; margin-left: 4px;" [nzTheme]="'twotone'" [nzTwotoneColor]="'#eb2f96'"></span>
                    <nz-spin class="appointment-loading" *ngIf="clearAppoinmentLoading" nzSimple [nzSize]="'small'"></nz-spin>
                    <span *ngIf="isTurnOnEditMode">
                      <a class="edit-item-shipment-entry" (click)="onBtnEditBookAppointment(location); $event.stopPropagation()">
                        <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Book Appointment
                      </a>
                    </span>
                  </div>
                </div>
                <div nz-col nzXXl="9" nzXl="9" nzLg="9" class="serviceOptions">
                  <img src="assets/img/service-options-icon.svg">
                  {{getServiceOptions(location)}}
                  <span *ngIf="isTurnOnEditMode">
                    <a class="edit-item-shipment-entry" (click)="onBtnEditServiceoption(location); $event.stopPropagation()">
                      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                    </a>
                  </span>
                </div>
              </div>
              <div nz-col *ngIf="i>0 && displayInfoTrafficForEachStops[i] && (displayInfoTrafficForEachStops[i].mileage || displayInfoTrafficForEachStops[i].hours)" style="margin-top: 3px;">
                <img src="/assets/img/dispatch-icons/route.svg" />
                <span class="left5">{{ displayInfoTrafficForEachStops[i].mileage }} mi - {{ displayInfoTrafficForEachStops[i].hours }} hr</span>
              </div>
              <ng-container *ngIf="isWarningAddressForOrderEDI(location)">
                <div>
                  <span class="warning-address">EDI does not have timezone information, so we are unable to determine the timezone of the {{location.type}} time based on the location address. Please check and confirm the time before creating the route.</span>
                </div>
              </ng-container>
            </ng-template>
      
            <div nz-row class="info">
              <div nz-col nzSpan="10">
                <div class="label-info primaryContact">Primary Contact: {{getPrimaryContact(location)}}
                  <span *ngIf="isTurnOnEditMode">
                    <a class="edit-item-shipment-entry" (click)="onBtnEditContactInfo(location, 'primary'); $event.stopPropagation()">
                      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                    </a>
                  </span>
                </div>
                <div class="label-info">
                  Phone Number: {{getPrimaryPhoneNumber(location)}} {{getPhoneExtension(location)}}
                </div>
                <div class="label-info">
                  Email: {{getPrimaryEmail(location)}}
                </div>
                <div class="label-info referenceNo">Reference No: {{getReferenceNo(location)}}
                  <span *ngIf="isTurnOnEditMode">
                    <a class="edit-item-shipment-entry" (click)="onBtnEditReferenceNumber(location); $event.stopPropagation()">
                      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                    </a>
                  </span>
                </div>
                <div class="label-info instructions">Instructions: {{getInstructions(location)}}
                  <span *ngIf="isTurnOnEditMode">
                    <a class="edit-item-shipment-entry" (click)="onBtnEditInstruction(location)">
                      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                    </a>
                  </span>
                </div>
              </div>
              <nz-divider class="divider-info" nzType="vertical"></nz-divider>
              <div nz-col nzSpan="10" nzOffset="1">
                <div class="label-info secondaryContact">Secondary Contact: {{getSecondaryContact(location)}}
                  <span *ngIf="isTurnOnEditMode">
                    <a class="edit-item-shipment-entry" (click)="onBtnEditContactInfo(location, 'secondary'); $event.stopPropagation()">
                      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                    </a>
                  </span>
                </div>
                <div class="label-info">
                  Phone Number: {{getSecondaryPhoneNumber(location)}} {{getSecondaryPhoneExtension(location)}}
                </div>
                <div class="label-info">
                  Email: {{getSecondaryEmail(location)}}
                </div>
                <div class="label-info accessCode">Access Code: {{getAccessCode(location)}}
                  <span *ngIf="isTurnOnEditMode">
                    <a class="edit-item-shipment-entry" (click)="onBtnEditAccessCode(location); $event.stopPropagation()">
                      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                    </a>
                  </span>
                </div>
                <div class="label-info notes">Notes: {{getNotes(location)}}
                  <span *ngIf="isTurnOnEditMode">
                    <a class="edit-item-shipment-entry" (click)="onBtnEditNote(location); $event.stopPropagation()">
                      <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <ng-container *ngIf="shouldShowItemsOfLocation(location)">
              <div *ngIf="!isSingleMode" nz-row>
                <div nz-col nzSpan="24" class="divider-item">
                  <nz-divider ></nz-divider>
                </div>
              </div>

              <div *ngIf="isTurnOnEditMode">
                <button nz-button (click)="onBtnEditItemsLocation(location)" class="left20 bottom10">
                  <i nz-icon nzType="edit" nzTheme="outline"></i>Edit Items
                </button>
              </div>
  
              <item-list *ngIf="!isSingleMode" [items]="location?.items"></item-list>
            </ng-container>
          </nz-collapse-panel>
        </nz-collapse>
      </ng-template>
    </nz-step>
    <nz-step *ngIf="isTurnOnEditMode && canAddAndRemoveStop" [nzTitle]="editStepHeader">
      <ng-template #editStepHeader>
        <div nz-row class="routeHeader">
          <button nz-button (click)="onBtnAddRemoveStops()">Add/Remove Stops</button>
        </div>
      </ng-template>
    </nz-step>
  </nz-steps>

  <div *ngIf="isSingleMode" class="items">
    <div class="label">
      <span>Items</span>
      <span *ngIf="isTurnOnEditMode">
        <a class="edit-item-shipment-entry" (click)="onBtnEditItemsOrderSingle()">
          <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
        </a>
      </span>
      <span *ngIf="canUploadItemManifest">
        <a class="edit-item-shipment-entry" (click)="onBtnUploadItemsManifest()">
          <i nz-icon nzType="upload" nzTheme="outline" style="margin-right: 5px;"></i>Upload Manifest
        </a>
      </span>
      <div class="items-history">
        <a class="edit-item-shipment-entry"
          (click)="onViewItemsHistory()"
        >
          <i nz-icon nzType="history" nzTheme="outline"></i>
          Items history
        </a>
      </div>
    </div>
    <item-list [items]="model?.items"></item-list>
  </div>

</div>
<!-- <ng-template #noteContent >
    <div class="note-content">
        <dispatch-notes-v2 [jobId]="jobId"></dispatch-notes-v2>
    </div>
</ng-template> -->